import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IssuingEntity } from "src/app/core/models/issuingEntity";
import { User } from "src/app/core/models/user";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { IssuingEntityIssuerDTO } from "src/app/dtos/issuingEntityIssuerDTO";
import { UsersService } from "../../users/users.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  code: string;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private notifications: NotificationsService
  ) {
    this.authenticationService.clearStorage();
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.code) {
        this.code = queryParams.code;
      } else {
        this.authenticationService.loginCognito();
      }
    });
  }

  ngOnInit(): void {
    if (this.code) {
      this.userService.getTokens(this.code).subscribe(
        (result) => {
          //USER LOCAL STORAGE
          const user: User = new User();
          user.token = result.accessToken;
          user.refreshToken = result.refreshToken;
          let expiresAt = new Date();
          expiresAt = new Date(expiresAt.getTime() + result.expiresIn * 1000);
          user.expiresTokenAt = expiresAt;
          user.secondsRefreshToken = result.expiresIn;
          this.authenticationService.setCurrentUser(user);

          //FIND USER PROFILE
          this.userService.getProfile().subscribe(
            (result) => {
              //UPDATE USER LOGAL STORAGE
              user.email = result.email;
              user.firstName = result.firstName;
              user.lastName = result.lastName;
              user.roles = [result.role];
              user.issuingEntites = [];
              user.canModifyBalance =  result.canModifyBalance;
              result.issuingEntityIssuers.forEach(
                (element: IssuingEntityIssuerDTO) => {
                  const issuingEntity: IssuingEntity = new IssuingEntity();
                  if (element.active) {
                    issuingEntity.id = element.id;
                    issuingEntity.code = element.code;
                    issuingEntity.name = element.name;
                    issuingEntity.complianceBuyerAuthorized =
                      element.complianceBuyerAuthorized;
                    user.issuingEntites.push(issuingEntity);
                  }
                }
              );
              this.authenticationService.setCurrentUser(user);
              this.router.navigate(["/"]);
            },
            (error) => {
              this.authenticationService.logout();
            }
          );

          this.userService.getUserProgram();
        },
        (error) => {
          this.authenticationService.clearStorage();
          if (typeof error === "string") {
            this.notifications.error("", error);
            setTimeout(() => {
              this.router.navigate(["/auth/login"]);
            }, 2000);
          }
        }
      );
    }
  }
}
