import { ProgramEligibilityDTO } from "./programEligibilityDTO";

export class Program {
  id: number;
  name: string;
  userIdVisible: boolean;
  userIdRequired: boolean;
  hideShopperName: boolean;
  permissionedMarketAssignment: boolean;
  pinStrategy: string;
  programEligibility?: ProgramEligibilityDTO[];
  lastUpdate?: Date;
}
