import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerConfig, BsDatepickerModule, DatepickerModule } from "ngx-bootstrap/datepicker";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";

import { ColorsService } from "./colors/colors.service";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { SelectIssuingEntityComponent } from "./components/select-issuing-entity/select-issuing-entity.component";
import { ViewTransactionComponent } from "./components/view-transaction/view-transaction.component";
import { EasypiechartDirective } from "./directives/easypiechart/easypiechart.directive";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { SortService } from "./tables/sort.service";
import { SortableColumnComponent } from "./tables/sortable-column.component";
import { SortableTableDirective } from "./tables/sortable-table.directive";

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    useUtc: true
  });
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // AccordionModule.forRoot(),
    // AlertModule.forRoot(),
    // ButtonsModule.forRoot(),
    // CarouselModule.forRoot(),
    // CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    // BsDropdownModule.forRoot(),
    // ModalModule.forRoot(),
    PaginationModule.forRoot(),
    // ProgressbarModule.forRoot(),
    // RatingModule.forRoot(),
    TabsModule.forRoot(),
    // TimepickerModule.forRoot(),
    // TooltipModule.forRoot(),
    // PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxIntlTelInputModule,
    CarouselModule.forRoot(),
  ],
  providers: [ColorsService, SortService, { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }],
  declarations: [
    SortableColumnComponent,
    SortableTableDirective,
    SelectIssuingEntityComponent,
    PageHeaderComponent,
    SparklineDirective,
    EasypiechartDirective,
    ViewTransactionComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // AccordionModule,
    // AlertModule,
    // ButtonsModule,
    // CarouselModule,
    // CollapseModule,
    DatepickerModule,
    BsDatepickerModule,
    // BsDropdownModule,
    // ModalModule,
    PaginationModule,
    // ProgressbarModule,
    TabsModule,
    // TimepickerModule,
    // TooltipModule,
    // PopoverModule,
    TypeaheadModule,
    SparklineDirective,
    // ScrollableDirective,
    SortableColumnComponent,
    SortableTableDirective,
    NgxIntlTelInputModule,
    SelectIssuingEntityComponent,
    CarouselModule,
    PageHeaderComponent,
    EasypiechartDirective,
    ViewTransactionComponent,
    // FlotDirective
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
