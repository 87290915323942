<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <!-- START navbar header-->
  <div class="navbar-header">
    <a class="navbar-brand" href="#/">
      <div class="brand-logo">
        <img class="logo" src="/assets/img/logo.png" alt="image" />

        <img class="solimarket" src="/assets/img/solimarket.png" alt="image" />
      </div>
      <div class="brand-logo-collapsed">
        <img src="/assets/img/logo.png" alt="image" />
      </div>
    </a>
  </div>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a
        class="nav-link d-none d-md-block d-lg-block d-xl-block"
        trigger-resize=""
        (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()"
      >
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a
        class="nav-link sidebar-toggle d-md-none"
        (click)="
          settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()
        "
      >
        <em class="fas fa-bars"></em>
      </a>
    </li>
  </ul>
  <ul class="navbar-nav flex-row mr-auto" *ngIf="issuingEntity && user.isInRole('AgencyUser')">
    <li
      class="nav-item brand-logo-collapsed"
      *ngFor="let item of benefits; let i = index"
    >
      <span *ngIf="item.active"
        ><span *ngIf="i > 0" class="mr-2 ml-2"> | </span>
        {{ item.benefitName }} Balance : ${{
          item.fundsAvailable | number : "1.0-2"
        }}
      </span>
    </li>
  </ul>
  <!-- END Left navbar-->
  <ul class="navbar-nav flex-row">
    <li class="nav-item">
      <a
        *ngIf="issuingEntity && user.issuingEntites.length > 1 && user.isInRole('AgencyUser')"
        class="nav-link"
        title="Agency"
        (click)="changeIssuingEntity()"
        style="cursor: pointer"
      >
        <span class="entity">{{ issuingEntity.name }}</span>

        <em class="bi bi-arrow-left-right"></em>
      </a>
      <label *ngIf="user.issuingEntites.length == 1" class="nav-link">
        <span class="entity">{{ user.issuingEntites[0].name }}</span>
      </label>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="'/users/profile'" title="Profile">
        <em class="bi bi-person"></em>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link cursor" (click)="logout()" title="Logout">
        <em class="bi bi-box-arrow-in-left"></em>
      </a>
    </li>
  </ul>
</nav>
<!-- END Top Navbar-->
<ng-template #selectIssuingEntity let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Agency</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-select-issuing-entity
      (refresh)="onRefresh($event)"
    ></app-select-issuing-entity>
  </div>
</ng-template>
