import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
    //{ path: '', redirectTo: 'login', pathMatch: 'full' },
    //{ path: 'login', component: LoginComponent },
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes),
    ],
    declarations: [
        LoginComponent,
    ],
    exports: [
        //RouterModule,
    ]
})
export class AuthenticationModule { }
