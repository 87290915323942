<div class="item user-block" *ngIf="userBlockIsVisible()">
  <div class="user-block-info">
    <span class="user-block-name"
      >{{ user?.firstName }} {{ user?.lastName }}</span
    >
    <span class="user-block-role">{{
      findUserRoleDescription(user?.roles)
    }}</span>
  </div>
</div>
