<div *ngIf="entities.length > 0; else emptyEntities">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <div class="select-entity" *ngIf="entities.length > 1">
          <label *ngFor="let entity of entities" class="c-radio p-2">
            <input
              name="entity"
              type="radio"
              value="{{ entity.code }}"
              (change)="handleChange($event, entity)"
              [checked]="entity?.code === current?.code"
            />
            <span class="fa fa-check"></span>
            {{ entity.code }} - {{ entity.name }}
          </label>
        </div>
        <div class="select-entity" *ngIf="entities.length == 1">
          <label *ngFor="let entity of entities">
            {{ entity.code }} - {{ entity.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-right">
      <button
        [disabled]="!selectedEntity"
        class="btn btn-sm btn-success ml-2"
        (click)="confirm()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
<ng-template #emptyEntities>
  You do not have assigned issuing entities
  <div class="row">
    <div class="col-md-12 text-right">
      <button class="btn btn-sm btn-primary ml-2" (click)="logout()">
        Logout
      </button>
    </div>
  </div>
</ng-template>
