export const environment = {
  version: require("../../package.json").version,
  production: false,
  staging: true,
  development: false,
  apiUrl: "https://apiportaltest2.soliportal.com/api/v1",
  notificationsUrl: "http://localhost:5000/notificationhub",
  serverStellar: "https://horizon-testnet.copernicuspay.cloud",
  stellarUser: "na",
  stellarPassword: "na",
  stellarNetwork: "na",
  cognito:
    "https://solimarket.auth.us-east-2.amazoncognito.com/login?client_id=3ae4g458njlb8bceopjp1l78hc&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://portaltest2.soliportal.com/auth/login",

  cognitoUrl: "https://solimarket.auth.us-east-2.amazoncognito.com/",
  cognitoClientId: "3ae4g458njlb8bceopjp1l78hc",
  cognitoClientSecret: "",

  cognitoRedirectUrl: "https://portaltest2.soliportal.com/auth/login",
  cognitoScope: "aws.cognito.signin.user.admin+email+openid+phone+profile",
  cognitoResponseType: "code",
};
