import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { AuthGuard } from "./core/guards/auth.guard";
import { SentryErrorHandler } from "./core/handler/sentry-error-handler";
import { LoaderInterceptor } from "./core/loading/loader.interceptor";
import { LoaderService } from "./core/loading/loader.service";
import { AuthenticationService } from "./core/services/authentication.service";
import { ErrorInterceptor, JwtInterceptor } from "./helpers";
import { LayoutModule } from "./layout/layout.module";
import { RoutesModule } from "./routes/routes.module";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      countDuplicates: true,
      preventDuplicates: true,
    }),
  ],
  providers: [
    AuthGuard,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
      deps: [AuthenticationService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
