import { Routes } from "@angular/router";
import { AuthGuard } from "../core/guards/auth.guard";
import { LayoutComponent } from "../layout/layout.component";
import { LoginComponent } from "./authentication/login/login.component";

export const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator", "AgencyUser"] },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator", "AgencyUser"] },
      },

      {
        path: "markets",
        loadChildren: () =>
          import("./markets/markets.module").then((m) => m.MarketsModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator"] },
      },
      {
        path: "participants",
        loadChildren: () =>
          import("./participants/participants.module").then(
            (m) => m.ParticipantsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator", "AgencyUser"] },
      },
      {
        path: "stores",
        loadChildren: () =>
          import("./stores/stores.module").then((m) => m.StoresModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator"] },
      },
      {
        path: "pending-farms",
        loadChildren: () =>
          import("./pending-farms/pending-farms.module").then(
            (m) => m.PendingFarmModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator"] },
      },
      {
        path: "issuing-entities",
        loadChildren: () =>
          import("./issuance-partners/issuing-entities.module").then(
            (m) => m.IssuingEntitiesModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator"] },
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (m) => m.TransactionsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator"] },
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./inventory/inventory.module").then((m) => m.InventoryModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator", "AgencyUser"] },
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./reports/reports.module").then((m) => m.ReportsModule),
        canActivate: [AuthGuard],
        data: { roles: ["Administrator", "AgencyUser"] },
      },
    ],
  },
  {
    path: "auth/login",
    component: LoginComponent,
  },
  {
    path: "error500",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  // Not found
  {
    path: "**",
    redirectTo: "home",
  },
];
