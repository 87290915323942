import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Pagination } from "src/app/core/dtos/pagination";
import { Program } from "src/app/core/models/program";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { BaseService } from "src/app/core/services/base.service";
import { IssuingEntityDTO } from "src/app/dtos/issuingEntityDTO";
import { JwTockensDTO } from "src/app/dtos/jwtTockensDTO";
import { ProgramConfigurationDTO } from "src/app/dtos/programConfigurationDTO";
import { UserDTO } from "src/app/dtos/userDTO";
import { UserListDTO } from "src/app/dtos/userListDTO";
import { UserPasswordUpdateDTO } from "src/app/dtos/userPasswordUpdateDTO";
import { UserUpdateDTO } from "src/app/dtos/userUpdateDTO";

@Injectable({
  providedIn: "root",
})
export class UsersService extends BaseService {
  programConf: ProgramConfigurationDTO;
  constructor(
    protected readonly http: HttpClient,
    private authService: AuthenticationService
  ) {
    super(http);
    this.setBaseUrl("UserManagement");
    setInterval(() => this.getUserProgram(), 600000);
  }

  getAll(
    filter: string,
    active: string,
    role: string,
    pageNumber: number = 1,
    sortColumn: string = "userName",
    sortDirection: number = 1,
    pageSize
  ): Observable<Pagination<UserListDTO>> {
    let params = new HttpParams();
    if (filter) {
      params = params.append("filter", filter);
    }
    if (active) {
      params = params.append("active", active);
    }
    if (role) {
      params = params.append("role", role);
    }
    if (sortColumn && sortDirection) {
      params = params.append("sortColumn", sortColumn);
      params = params.append("sortDirection", sortDirection.toString());
    }
    if (pageSize) {
      params = params.append("pageSize", pageSize.toString());
    }
    params = params.append("page", pageNumber.toString());

    return this.http.get<Pagination<UserListDTO>>(this.buildUrl(""), {
      params,
    });
  }

  getUsersForStore(
    filter: string,
    storeId: number,
    role: string,
    active: string,
    pageNumber: number = 1,
    sortColumn: string = "lastName",
    sortDirection: number = 1
  ): Observable<Pagination<UserListDTO>> {
    let params = new HttpParams();
    if (filter) {
      params = params.append("filter", filter.toString());
    }
    if (storeId) {
      params = params.append("storeId", storeId.toString());
    }
    if (role) {
      params = params.append("role", role.toString());
    }
    if (active) {
      params = params.append("active", active);
    }
    if (sortColumn && sortDirection) {
      params = params.append("sortColumn", sortColumn);
      params = params.append("sortDirection", sortDirection.toString());
    }
    params = params.append("page", pageNumber.toString());

    return this.http.get<Pagination<UserListDTO>>(
      this.buildUrl("/SearchForStore"),
      { params }
    );
  }

  getProfile() {
    return this.http.get<UserDTO>(this.buildUrl(`/GetCurrentProfile`), {});
  }
  updateProfile(entity: UserUpdateDTO): Observable<any> {
    return this.http.put(this.buildUrl(`/UpdateCurrentProfile`), entity);
  }

  getIsVerified(id: number): Observable<any> {
    return this.http.get<any>(this.buildUrl(`/${id}/isVerified`));
  }

  resendInvitation(id: number): Observable<any> {
    return this.http.post(this.buildUrl(`/${id}/resendInvitation`), null);
  }

  activate(id: number): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Activate`), {});
  }

  deactivate(id: number): Observable<UserDTO> {
    return this.http.put<UserDTO>(this.buildUrl(`/${id}/Deactivate`), {});
  }

  changePassword(dto: UserPasswordUpdateDTO): Observable<UserDTO> {
    return this.http.put<UserDTO>(
      this.buildUrl(`/UpdateCurrentProfilePassword`),
      dto
    );
  }

  getUserProgram(): Observable<Program> {
    const program = this.authService.currentProgram();

    const minutesLastUpdate = moment
      .duration(moment().diff(moment(program?.lastUpdate)))
      .asMinutes();
    if (minutesLastUpdate > 5 || !program) {
      return this.http.get<Program>(this.buildUrl(`/Program`), {}).pipe(
        map((response) => {
          this.authService.setCurrentProgram(response);
          return response;
        })
      );
    } else {
      return of(program);
    }
  }
  getTokens(code: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/GetUserJwtTokens/${code}`)
    );
  }
  refreshTokens(refreshToken: string): Observable<JwTockensDTO> {
    return this.http.get<JwTockensDTO>(
      this.buildUrl(`/RefreshUserJwtTokens/${refreshToken}`)
    );
  }
  getUserAgency(id: number) {
    return this.http.get<IssuingEntityDTO>(this.buildUrl(`/Agency/${id}`));
  }

  getProgramConfiguration() {
    return this.http.get<ProgramConfigurationDTO>(
      this.buildUrl(`/ProgramConfiguration`)
    );
  }
}
