import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { plainToClass } from "class-transformer";
import { IssuingEntity } from "src/app/core/models/issuingEntity";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { AuthenticationService } from "src/app/core/services/authentication.service";

import moment from "moment";
import { IssuingEntityBenefitBalanceDTO } from "src/app/dtos/issuingEntityBenefitBalanceDTO";
import { IssuingEntitiesService } from "src/app/routes/issuance-partners/issuing-entities.service";
import { MenuService } from "../../core/menu/menu.service";
import { SettingsService } from "../../core/settings/settings.service";
import { UserblockService } from "../sidebar/userblock/userblock.service";
import { User } from "./../../core/models/user";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("selectIssuingEntity") selectIssuingEntity: ElementRef;

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  router: Router;
  user: User;
  issuingEntity: IssuingEntity;

  isNavSearchVisible: boolean;
  @ViewChild("fsbutton", { static: true }) fsbutton; // the fullscreen button

  benefits: IssuingEntityBenefitBalanceDTO[] = [];
  now: Date = new Date();
  moment: any = moment;
  benefitEntity: IssuingEntityBenefitBalanceDTO;

  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public injector: Injector,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private readonly notificationsService: NotificationsService,
    private issuingEntityService: IssuingEntitiesService
  ) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.user = this.currentUser();
  }

  ngOnInit(): any {
    this.isNavSearchVisible = false;

    const ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = "none";
    }

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });

    this.issuingEntity =
      this.user.issuingEntites.length == 1
        ? this.user.issuingEntites[0]
        : this.authService.currentIssuingEntity();
    this.getIssueEntityBenefits(this.issuingEntity);

    this.issuingEntityService.getBalance.subscribe((bal) => {
      if (bal == true) {
        this.getIssueEntityBenefits(this.issuingEntity);
      }
    });
  }

  currentUser(): User {
    try {
      const transformed = plainToClass(
        User,
        JSON.parse(localStorage.getItem("currentUser"))
      );

      if (transformed) {
        if (Array.isArray(transformed)) {
          return transformed[0];
        }
        return transformed;
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  toggleUserBlock(event): any {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event): any {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean): any {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible(): any {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar(): any {
    this.settings.toggleLayoutSetting("offsidebarOpen");
  }

  toggleCollapsedSideabar(): any {
    this.settings.toggleLayoutSetting("isCollapsed");
  }

  isCollapsedText(): any {
    return this.settings.getLayoutSetting("isCollapsedText");
  }

  toggleFullScreen(event): any {}
  changeIssuingEntity() {
    this.modalService.open(this.selectIssuingEntity, {
      centered: true,
      size: "md",
    });
  }
  logout() {
    this.authService.logout();
  }

  getIssueEntityBenefits(issuingEntity: IssuingEntity) {
    if (issuingEntity) {
      this.issuingEntityService
        .getBenefits(issuingEntity.id)
        .subscribe((result) => {
          this.benefits = result;
          this.benefits.forEach((element) => {
            element.active = moment(this.now).isBetween(
              moment(element.startValidityDate),
              moment(element.endValidityDate)
            );
            element.expired = moment(this.now).isAfter(
              moment(element.endValidityDate)
            );
          });
        });
    }
  }

  onRefresh(item: any) {
    if(item == 'added') {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }
}
