import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { environment } from "./../../../environments/environment";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    if (!environment.development) {
      Sentry.init({
        dsn: "https://0e38bb0216144a06bbcc22e763fd58b2@o390927.ingest.sentry.io/5774110",
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ["localhost", "https://portaltest.soliportal.com"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],

        tracesSampleRate: 1.0,
        maxValueLength: 1000,
        environment: environment.production
          ? "production"
          : environment.staging
          ? "testing"
          : "development",
        release: "shopper-portal@" + environment.version,
      });
    }
  }

  handleError(error) {
    if (!environment.development) {
      Sentry.captureException(error.originalError || error);
    }
    if (environment.development) {
      throw error;
    }
  }
}
