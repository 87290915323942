const Home = {
  text: "Home",
  link: "/home",
  icon: "bi bi-house-door",
  roles: ["Administrator", "AgencyUser"],
};

const Users = {
  text: "Users",
  link: "/users",
  icon: "bi bi-person",
  roles: ["Administrator"],
};

const Markets = {
  text: "Markets",
  link: "/markets",
  icon: "bi bi-cart",
  roles: ["Administrator"],
};

const Farms = {
  text: "Farms",
  link: "/stores",
  icon: "bi bi-shop-window",
  roles: ["Administrator"],
};

// const PendingFarms = {
//   text: "Pending Farms",
//   link: "/pending-farms",
//   icon: "bi bi-shop-window",
//   roles: ["Administrator"],
// };

const Participants = {
  text: "Participants",
  link: "/participants",
  icon: "bi bi-file-earmark-person",
  roles: ["Administrator", "AgencyUser"],
};

const IssuingEntities = {
  text: "Issuance Partners",
  link: "/issuing-entities",
  icon: "bi bi-heart-pulse",
  roles: ["Administrator"],
};

const Transactions = {
  text: "Transactions",
  link: "/transactions",
  icon: "bi bi-coin",
  roles: ["Administrator"],
};

const Inventory = {
  text: "Inventory",
  link: "/inventory",
  icon: "bi bi-inbox-fill",
  roles: ["Administrator", "AgencyUser"],
};

const Reports = {
  text: "Reports",
  link: "/reports",
  icon: "bi bi-journal-arrow-down",
  roles: ["Administrator"],
};

export const menu = [
  Home,
  Users,
  Markets,
  Participants,
  Farms,
  // PendingFarms,
  IssuingEntities,
  Transactions,
  Inventory,
  Reports,
];
