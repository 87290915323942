import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IssuingEntity } from "src/app/core/models/issuingEntity";
import { User } from "src/app/core/models/user";
import { AuthenticationService } from "src/app/core/services/authentication.service";

@Component({
  selector: "app-select-issuing-entity",
  templateUrl: "./select-issuing-entity.component.html",
  styleUrls: ["./select-issuing-entity.component.scss"],
})
export class SelectIssuingEntityComponent implements OnInit {
  entities: IssuingEntity[];
  selected: IssuingEntity;
  current: IssuingEntity;
  selectedEntity: boolean = false;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  user: User;
  constructor(
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.entities = this.authenticationService.getIssuingEntitiesUser();
    this.current = this.authenticationService.currentIssuingEntity();
    this.user = this.authenticationService.currentUser();
    this.selectedEntity = false;
    if (this.current != undefined && this.user.isInRole('AgencyUser')) {
      this.selectedEntity = true;
    } else {
      this.current = null;
    }
    if (this.entities.length == 1) {
      this.selected = this.entities[0];
      this.selectedEntity = true;
    }
  }
  handleChange(evt, entity) {
    var target = evt.target;
    if (target.checked) {
      this.selected = entity;
      this.selectedEntity = true;
    }
  }
  confirm() {
    if (this.entities.length == 1) {
      this.selected = this.entities[0];
    }
    if (this.selected != undefined) {
      if (this.selected.code != this.current?.code) {
        const issuingEntity = new IssuingEntity();
        issuingEntity.id = this.selected.id;
        issuingEntity.code = this.selected.code;
        issuingEntity.name = this.selected.name;
        issuingEntity.complianceBuyerAuthorized =
          this.selected.complianceBuyerAuthorized;
        localStorage.setItem("issuingEntity", JSON.stringify(issuingEntity));
        if(this.user.isInRole('AgencyUser')) {
          this.refresh.emit("added");
        } else {
          this.refresh.emit("new-participant");
        }
      }
    }
    this.modalService.dismissAll();
    if (this.router.routerState.snapshot.url != "/home" && this.user.isInRole('AgencyUser')) {
      this.router.navigate(["/home"]);
    }
  }

  logout() {
    this.modalService.dismissAll();
    this.authenticationService.logout();
  }
}
